<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-1 pb-1'>
                    <ManagerUserLeftPanel :data-item='dataItem' />
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger p-component mr-2 pl-3 pr-3 pt-2 pb-2' />

                <Sidebar v-model:visible="visibleLeft" :baseZIndex="1000">

					<h3>Temel Bilgiler</h3>

					<p class="text-lg">
						<b>Adı ve Soyadı:</b> Yetkili kişinin adını giriniz Örnek Yazım Şekli: Tarık<br>
						<br>
						<b>E-Posta Adresi:</b> Kuruma ait alan adı uzantılı e-posta adresi giriniz.<br>
						<br>
						<b>GSM:</b> Başında 0 (sıfır) olmadan yetkili kişinin mobil numarasını giriniz.<br>
						<br>
						<b>Şifre:</b> Şifre en az 6 (altı) karakter olmalı ve içinde en az bir büyük harf, bir küçük harf, sayı ve sembolden oluşmalıdır.<br>
						<br>
                        <b>Yönetici Türü:</b> Yöneticiye belirlediğiniz rolü belirleyiniz. Rolü dışında bir yetki türü seçmeyiniz.<br>
						<br>
						<b>Durum:</b> Kullanıcıyı pasif etmeniz durumunda tekrar giriş yapamaz. Pasif olduğunda tarih ve nedenini yazmanız gerekmektedir. Örnek: İşten Ayrılma, Görev Değişimi vs.<br>
						<br>

                        <span></span>
                        <span class="text-red-500 font-bold ml-2 mr-2">*</span> işaretli alanlar zorunlu alanlardır.<br>
					</p>

				</Sidebar>

				</span>

            </div>


        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <ManagerUserTabMenu :manager-user-id='managerUserId'></ManagerUserTabMenu>

                <div class='grid'>

                    <div class='col-12'>

                        <div class='field grid mb-5'>
                            <label for='name' class='col-12 mb-2 md:col-2 md:mb-0'>Adı<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <InputText id='name' v-model='dataItem.name' type='text' />
                            </div>
                        </div>


                        <div class='field grid mb-5'>
                            <label for='surName' class='col-12 mb-2 md:col-2 md:mb-0'>Soyadı<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <InputText id='surName' v-model='dataItem.surName' type='text' />
                            </div>
                        </div>

                        <div class='field grid mb-5'>
                            <label for='email' class='col-12 mb-2 md:col-2 md:mb-0'>E-Posta Adresi<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <InputText id='email' v-model='dataItem.email' type='text' />
                            </div>
                        </div>

                        <div class='field grid mb-5'>
                            <label for='gsmNumber' class='col-12 mb-2 md:col-2 md:mb-0'>GSM<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <InputText id='gsmNumber' v-model='dataItem.gsmNumber' type='text' maxlength='10' />
                            </div>
                        </div>

                        <div class='field grid mb-5'>
                            <label for='password' class='col-12 mb-2 md:col-2 md:mb-0'>Şifre<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <InputText id='password' v-model='dataItem.password' type='text' />
                            </div>
                        </div>

                        <div class='field grid mb-5'>
                            <label for='userType' class='col-12 mb-2 md:col-2 md:mb-0'>Yönetici Türü<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <Dropdown id='userType' v-model='dataItem.userType' :options='userTypes' optionLabel='name' option-value='value' placeholder='Seçim Yapın' />
                            </div>
                        </div>

                        <div class='field grid mb-5'>
                            <label for='isActive' class='col-12 mb-2 md:col-2 md:mb-0'>Durum</label>
                            <div class='col-12 md:col-10'>
                                <InputSwitch id='isActive' v-model='dataItem.isActive' />
                            </div>
                        </div>

                        <div class='field grid mb-5' v-if='!dataItem.isActive'>
                            <div class='col-12'>
                                <hr>
                            </div>
                        </div>

                        <div class='field grid mb-5' v-if='!dataItem.isActive'>
                            <label for='passiveDate' class='col-12 mb-2 md:col-2 md:mb-0'>Pasif Tarihi</label>
                            <div class='col-12 md:col-10'>
                                <Calendar id='passiveDate' dateFormat='dd.mm.yy' :showIcon='true' :showButtonBar='true' v-model='dataItem.passiveDate'></Calendar>
                            </div>
                        </div>

                        <div class='field grid mb-5' v-if='!dataItem.isActive'>
                            <label for='passiveReason' class='col-12 mb-2 md:col-2 md:mb-0'>Pasif Nedeni</label>
                            <div class='col-12 md:col-10'>
                                <InputText id='passiveReason' v-model='dataItem.passiveReason' type='text' />
                            </div>
                        </div>


                        <div class='field grid'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                            <div class='col-12 md:col-10'>
                                <Button id='save' label='Kaydet' icon='pi pi-save' class='mr-2 mb-2' @click='save'></Button>
                            </div>
                        </div>


                    </div>


                </div>

            </div>

        </div>


    </div>
</template>

<script>

import ManagerUserService from '@/services/managerUserService';
import { showErrorMessage, showSuccessMessage } from '@/components/shared/toast/toastFunction';
import UserTypeService from '@/services/userTypeService';
import AdminToast from '@/components/shared/toast/adminToast';
import ManagerUserLeftPanel from './managerUserLeftPanel';
import ManagerUserTabMenu from './managerUserTabMenu';
import { showValidationMessage } from '../../components/shared/toast/toastFunction';
import { checkUserRole } from '../common/commonConstantFunctions';
import { getManagerUser } from '../common/commonFunctions';

export default {
    _managerUserService: null,
    _userTypeService: null,
    components: { ManagerUserTabMenu, ManagerUserLeftPanel, AdminToast },
    created() {
        checkUserRole(this,'Admin');
        this._managerUserService = new ManagerUserService();
        this._userTypeService = new UserTypeService();
        this.managerUserId = this.$route.params.managerUserId;
    },
    async mounted() {
        this.dataItem = await getManagerUser(this.managerUserId);
        this.getUserTypes();
    },
    data() {
        return {
            display: false,
            visibleLeft: false,
            managerUserId: 0,
            dataItem: {
                name: '',
                surName: '',
                isActive: true,
                email: '',
                gsmNumber: '',
                password: '',
                userType: '',
                passiveDate: '',
                passiveReason: '',
            },
            userTypes: [],
        };
    },

    methods: {
        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },
        async save() {
            if(this.dataItem.isActive == true)
            {
                this.dataItem.passiveDate = null;
                this.dataItem.passiveReason = ''
            }
            if(this.validateForm())
            {
                const updateResponse = await this._managerUserService.updateManager(this.managerUserId, this.dataItem);
                if (updateResponse.isSuccess) {
                    showSuccessMessage(this, 'Yönetici Güncelleme Başarılı');
                } else {
                    showErrorMessage(this, 'Yönetici Eklemede Sorun İle Karşılaşıldı');
                }
            }

        },

        getUserTypes() {
            this.userTypes = this._userTypeService.getUserTypes();
        },
        validateForm() {

            if(this.dataItem.isActive == false && (this.dataItem.passiveDate == null || this.dataItem.passiveReason == null || this.dataItem.passiveDate == '' || this.dataItem.passiveReason == ''))
            {
                showValidationMessage(this, "Yönetici Pasif yapıldığında tarih ve sebep belirtmelisiniz.");
                return false;
            }

            if(this.dataItem.name == "")
            {
                showValidationMessage(this, "Yönetici Adı Alanı Boş Bırakılamaz");
                return false;
            }

            if(this.dataItem.surName == "")
            {
                showValidationMessage(this, "Yönetici Soyadı Alanı Boş Bırakılamaz");
                return false;
            }

            if(this.dataItem.email == "")
            {
                showValidationMessage(this, "Yönetici E-Posta Adresi Alanı Boş Bırakılamaz");
                return false;
            }

            if(this.dataItem.userType == "")
            {
                showValidationMessage(this, "Yönetici Türü Seçimi Boş Bırakılamaz");
                return false;
            }

            if(this.dataItem.gsmNumber == "")
            {
                showValidationMessage(this, "Yönetici GSM Alanı Boş Bırakılamaz");
                return false;
            }

            return true;
        }

    },

};

</script>


