
export default class UserTypeService {


    getUserTypes() {
        return [
            {
                name:"Admin",
                value:"Admin"
            },
            {
                name: "Proje Müdürü",
                value: "Proje Müdürü"
            },
            {
                name: "Muhasebe",
                value: "Muhasebe"
            },
            {
                name: "Proje Yetkilisi",
                value: "Proje Yetkilisi"
            }
        ]
    }
}